export default class MainMenuStructureElement {
  title: string
  icon: string
  link: string
  isChapter: boolean
  outerLink: boolean
  specialClass: string | undefined
  children: MainMenuStructureElement[]
  hidden: boolean

  constructor (title: string,
               icon: string,
               link: string,
               isChapter: boolean = false,
               outerLink: boolean = false,
               specialClass?: string,
               ...children: MainMenuStructureElement[]) {
    this.title = title
    this.icon = icon
    this.link = link
    this.isChapter = isChapter
    this.children = children
    this.outerLink = outerLink
    this.specialClass = specialClass

  }
}
