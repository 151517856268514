import a, { AxiosPromise } from 'axios'
import API from 'api'
import TestCategory from 'model/TestCategory'
import Test from 'model/TestData'
import CountryRegion from '../model/CountryRegion'
import Project from '../model/Project'

export default class FiltersAPI {
  public static getTestCategories (projectId: string): AxiosPromise<TestCategory[]> {
    return this.getFilterData('testCategories', { projectId: projectId })
  }

  public static getTests (projectId: string): AxiosPromise<Test[]> {
    return this.getFilterData('tests', { projectId: projectId })
  }

  public static getOrgNames (region: { code: number; name: string; id: string }, projectId: string): AxiosPromise<string[]> {
    return a.post(API.api2 + '/filters/orgnames', { ...region, projectId: projectId })
  }

  public static getRegions (projectId: string): AxiosPromise<CountryRegion[]> {
    return this.getFilterData('regions', { projectId: projectId })
  }

  public static getProjects (): AxiosPromise<Project[]> {
    return this.getFilterData('projects', {})
  }

  private static getFilterData (entityName, params: {}): AxiosPromise<any[]> {
    return a.get(API.api2 + '/filters/' + entityName.toLowerCase(), {
      params: params
    })
  }
}
